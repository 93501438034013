* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: lightblue;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  font-family: "Montserrat", sans-serif;
  perspective: 1000px;
}

@keyframes hello {
  from {
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale(0.5);
  }
  to {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
}

.hello {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
  transform: translate3d(-50%, -50%, 0);
  text-shadow: 0 0 1em black;
  animation: hello 8s;
  animation-fill-mode: forwards;
  animation-delay: 3s;
}

.hello span {
  display: block;
  text-transform: uppercase;
  text-align: center;
  line-height: 0.8em;
  color: white;
}

.tillykke {
  font-size: 13vw;
}

.chris {
  font-size: 18vw;
}

.what {
  font-size: 9vw;
}

.x {
  font-size: 12vw;
}

.jersey {
  font-size: 18vw;
}

.ved {
  margin-top: 0.2em;
  font-size: 22vw;
}

.who {
  margin-top: 0.5em;
  font-size: 3vw;
}
